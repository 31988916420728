<template>
  <div class="videoContent">
    <video class="videoElement" id="my-video" :src="videoSrc" autoplay loop muted controls width="100%">
    </video>
  </div>
</template>

<script>
import videoJs from 'video.js'
import 'videojs-contrib-hls'
import 'video.js/dist/video-js.css'

export default {
  data () {
    return {
      videoSrc: '',
    }
  },
  computed: {
    url: function () {
      return this.$route.query.url;
    }
  },
  watch: {
    url() {
      this.loadVideoUrl();
    }
  },
  mounted() {
    this.loadVideoUrl()
  },
  methods: {
    loadVideoUrl(){
      this.videoSrc = `${process.env.VUE_APP_OBS_PREFIX}/`+this.url;
    }
  }
}
</script>

<style lang="scss" scoped>
#my-video{

}
.videoElement{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh);
  color: #333333;
}
</style>
